.App {
	height: 100%;
}

/* Johns Tweaks */

.ui.segment.covid {
	background-color: #FEDC56;
}

.ui.inverted.segment {
	/* background: pink;
 border:1px solid red; */
}

.ui.segment.covid label {
	color: black !important;
}

.ui.inverted.segment {
	/* color: red; */
}

.ui.inverted.segment label {
	/* color:red !important; */
}

div.item {
	border-radius: 0;
}
.ui.form {
	border-radius: 5pt;
}

.ui.form.studentinfo {
	width: 80%;
	margin: auto;
}

.ui.form.studentinfo input[type="text"] {
	width: 100%;
}

.ui.form.studentinfo .ui.input {
	width: 100%;
}

.ui.form.schoolinfo {
	width: 80%;
	margin: auto;
}

.ui.button.save {
	color: white;
	background-color: #2e5b80;
	margin-top: 5pt;
}

.ui.button.save:hover {
	color: white;
	background-color: #2e5b80;
	margin-top: 5pt;
	background-color: #4b95ca !important;
}

.ui.stackable.menu {
	padding: 0;
	margin: 0;
	width: 100% !important;
	border-radius: none !important;
}

.ui.menu .item:first-child {
	border: none !important;
	border: 1px solid red;
}

.ui.form .field > label {
	color: #2e5b80;
}

h3 {
	color: #2e5b80;
}

.ui.form:not(.login) {
	margin-top: 10pt;
	background-color: white;
	padding: 20pt;
	border: 1px solid #ccc;
}

.ui.fluid.menu a {
	color: #2e5b80;
}

.ui.table tr > th {
	color: #2e5b80;
}

.ui.table.compact tr > td {
	background-color: white;
}

.ui.attached.tabular.menu {
	padding: 0;
	margin: 0;
	background-color: white;
}

.ui.bottom.attached.segment {
	background-color: #f9fbfb;
}

.ui.container {
	width: 100%;
	min-height: 100vh;
}

.ui.attached.tabular.menu a {
	/* border-top:1px solid #2E5B80;	 */
	color: white;
	background-color: #2e5b80;
}

.ui.attached.tabular.menu a.active.item {
	/* border-top:1px solid #2E5B80;	 */
	color: #2e5b80;
	background-color: white;
}

.ui.attached.tabular.menu {
	border-top: 1px solid #2e5b80;
	background-color: #2e5b80;
	color: #2e5b80;
}

.ui.blue.center.aligned.header {
	background-color: #4b95ca !important;
	color: white !important;
	padding: 10pt;
	margin-top: 10pt;
	margin-bottom: 20pt;
	font-size: 14pt;
	letter-spacing: 1pt;
	border-radius: 2pt;
}

.ui.stackable .column {
	border: 1px solid #e8ebee;
	max-width: 300pt;
	height: 300pt;
	border-top-left-radius: 2pt;
	border-bottom-left-radius: 2pt;
	border-top-right-radius: 2pt;
	border-bottom-right-radius: 2pt;
}

.ui.stackable .column {
	border: 1px solid #e8ebee;
	max-width: 300pt;
	height: 300pt;
	border-top-left-radius: 2pt;
	border-bottom-left-radius: 2pt;
	border-top-right-radius: 2pt;
	border-bottom-right-radius: 2pt;
}

.headtitle {
	vertical-align: middle;
}

.panel-one {
	position: absolute;
	z-index: 10;
	background-color: #2e5b36;
	color: white;
	padding: 10pt;
	letter-spacing: 1pt;
	opacity: 0;
	top: 100px;
	left: 0px;
}

.panel-two {
	position: absolute;
	z-index: 10;
	top: 200px;
	right: 0px;
	background-color: #4b95ca;
	color: white;
	padding: 10pt;
	letter-spacing: 1pt;
	opacity: 0;
}

.panel-three {
	position: absolute;
	z-index: 10;
	top: 300px;

	background-color: #2e5b80;
	color: white;
	padding: 10pt;
	letter-spacing: 1pt;
	opacity: 1;
	transform: translateX(0px);
	/* Then transition these chained properties */
	transition: opacity 0.5s 1s, transform 0.5s 1s;
}

.show-messages {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

.show-messages2 {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

.show-messages3 {
	animation-name: shake;
	animation-duration: 1000ms, 1000ms;
	animation-timing-function: ease-in, ease-out;
	animation-iteration-count: 1, 1;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes shake {
	0% {
		transform: translate(1px, 1px) rotate(0deg);
	}
	10% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}
	20% {
		transform: translate(-3px, 0px) rotate(1deg);
	}
	30% {
		transform: translate(3px, 2px) rotate(0deg);
	}
	40% {
		transform: translate(1px, -1px) rotate(1deg);
	}
	50% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}
	60% {
		transform: translate(-3px, 1px) rotate(0deg);
	}
	70% {
		transform: translate(3px, 1px) rotate(-1deg);
	}
	80% {
		transform: translate(-1px, -1px) rotate(1deg);
	}
	90% {
		transform: translate(1px, 2px) rotate(0deg);
	}
	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}

@keyframes falling {
	0% {
		transform: translate3d(0, -400px, 0);
	}
	100% {
		transform: translate3d(0, 40%, 0) rotateX(30deg) rotateY(0deg)
			rotateZ(60deg);
	}
}

/* --------------------------------------- */

body {
	background-color: #f9fbfb;
	color: #666;
}

.ui.fluid.input {
	color: 666;
}

.ui.table tr td {
	color: #666;
}

.ui.table > .ui.button.save {
	border: 1px solid red;
}

.App .appHeader {
	background-color: #4b95ca !important;
}

.header h1 {
	color: white;
	padding: 10px;
}

.ui.stackable.menu {
	/* margin-top: -10pt; */
	padding: 0;
	background-color: #4b95ca !important;
}

.ui.stackable.menu a {
	color: white !important;
}

.ui.stackable.menu .itemtitle {
	color: white !important;
	font-size: 18pt;
}

.button-box {
	margin-top: 10pt !important;
}

.primary {
	margin-top: 5pt !important;
}

.question {
	display: block !important;
}

/* -----Please do not delete---- */
/* hiding the spinners in  input type number for webkit and moz based browsers: */

/* student-info  form*/
.ui.form input[type="number"] {
	-moz-appearance: textfield;
}

.ui.form input[type="number"]::-webkit-outer-spin-button,
.ui.form input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
.student-form-btn {
	margin: 1em !important;
	background-color: #4b95ca !important;
	color: white !important;
}

/*---student-info location-log-map----*/
.map_student_info {
	font-weight: bold;
}

/* location-alerts */
.icon-triangle {
	color: red;
}
/* list tabs - student-list and student-info */
/* .listtab {
	margin: 5em;
} */

/* .listtab a.active {
	background-color: #4b95ca !important;
	color: white !important;
} */

.student-list-header {
	margin-top: 1em !important;
}
.pagination {
	margin-top: 1% !important;
	box-shadow: none !important;
}
.student-list-dropdown {
	min-width: fit-content !important;
	line-height: 1.3em !important;
	/* min-height: 1em !important; */
	margin-top: 1.3% !important;
	font-size: 0.78571429rem;
}
.student-list-dropdown.ui.dropdown .menu > .item {
	font-size: inherit !important;
}

/* react datepicker container  */
.react-datepicker-wrapper .react-datepicker__input-container > .date-picker {
	width: 18em !important;
}
.react-datepicker-wrapper
	.react-datepicker__input-container
	> .flightdate-picker {
	/* width: 38em !important; */
}
/* change dropdown color for COVID test results */
.text-green.ui.dropdown > .text,
.ui.dropdown .menu .active.selected.item > .text {
	color: green;
}
.text-red.ui.dropdown > .text,
.ui.dropdown .menu .active.selected.item > .text {
	color: red;
}
.text-orange.ui.dropdown > .text,
.ui.dropdown .menu .active.selected.item > .text {
	color: orange;
}
/* .std-form-label {
	color: #4b95ca !important;
} */
.covid-header {
	margin-top: 1.5em !important;
	margin-bottom: -0.5em !important;
}
.reports-card-header {
	background-color: #e6e6e6 !important;
}

.qstudents_days {
	margin: 5em;
}
.alert_red {
	color: red !important;
}

.question {
	display: block !important;
	/* vertical-align: top !important; */
	/*padding-bottom: 20pt;*/
	color: #2e5b80 !important;
	font-size: medium;
	text-align: left;
	margin-left: 2pt;
	margin-bottom: 10pt;
}

.chart {
	float: left !important;
	padding: 0 !important;
	margin: 0 !important;
}

.form-section {
	border: 1px solid #ccc;
}

/* table.ui.celled td input:first-child {
	margin-top: 17pt;
}*/

table.ui.celled td {
	width: 200pt;
}

.reportTable.ui.celled td {
	width: 200pt;
}

.reportTable .ui.labeled.icon.button {
	width: 250pt;
}

.survey-completion-report.ui.celled td {
	width: 100pt;
}
.mainmenu.ui.menu {
	border-radius: 0;
}
