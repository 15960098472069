

.ui.stackable.surveyview .ui.table {
   background-color:white;
   border:1px solid #ccc;
}

.ui.stackable.surveyview > .column{
   border:1px solid red;
   margin:20pt;
   border:1px solid #ccc;
   width: 80%;
   height:100%;
}