.login-page .ui.stackable .column:first-child{
    background-color: #4b95ca;    
} 

.login-page .ui.blue.large.fluid.button{
    background-color: #2E5B80;  
    border:1px solid #2E5B80; 
   
}
.login-page .ui.blue.large.fluid.button:hover{
    background-color: #4b95ca;  
    border:1px solid #a3a3a3; 
}


.login-page .ui.grid div.column:nth-child(2){
    padding:0;
    margin:0;    
}
